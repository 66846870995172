import { FC } from 'react'

// Styles
import styles from './OrderItemImage.module.scss'

type Props = {
  imageForDisplay: JSX.Element
}

export const OrderItemImage: FC<Props> = (props) => {
  const { imageForDisplay } = props

  return <div className={styles.image}>{imageForDisplay}</div>
}
