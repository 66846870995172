import { gql, useApolloClient } from '@apollo/client'

// Models
import {
  UpdateOrderMetaDataInput,
  UpdateOrderMetaDataMutation,
  UpdateOrderMetaDataOutput,
} from '@obeta/schema'

const UPDATE_ORDER_META_DATA_MUTATION = gql`
  mutation updateOrderMetaData($input: UpdateOrderMetaDataInput!) {
    updateOrderMetaData(input: $input) {
      success
      errorCode
      errorMessage
      orderId
    }
  }
`

export const useOrders = () => {
  const client = useApolloClient()

  /**
   * Patches the meta data for an given order.
   * @param input UpdateOrderMetaDataInput
   * @returns Upda
   */
  const updateMetaData = async (
    input: UpdateOrderMetaDataInput
  ): Promise<UpdateOrderMetaDataOutput | undefined> => {
    const response = await client.mutate<UpdateOrderMetaDataMutation>({
      mutation: UPDATE_ORDER_META_DATA_MUTATION,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
    return response.data?.updateOrderMetaData
  }

  return {
    updateMetaData,
  }
}
