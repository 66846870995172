import { forwardRef, memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Typography, Collapse } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as ChevronRightIcon } from 'assets/icon/designsystem/chevron_right.svg'
import { ReactComponent as EditIcon } from 'assets/icon/designsystem/edit.svg'
import { ReactComponent as KeyboardArrowDownIcon } from 'assets/icon/designsystem/keyboard_arrow_down.svg'
import { ReactComponent as KeyboardArrowUpIcon } from 'assets/icon/designsystem/keyboard_arrow_up.svg'
import { ReactComponent as MoreHorizIcon } from 'assets/icon/designsystem/more_horiz.svg'
import brokenimage from 'assets/img/shoppingcartoverview/broken_image.svg'
import { OrderStateItem } from './OrderStateItem'
import { ImgProxyImage } from '../img-proxy-image/ImgProxyImage'
import { ProductOxomiImage } from '../product-images/ProductOxomiImage'

import { OrderInfo } from './OrderInfo'
import { OrderItemImage } from './OrderItemImage'
import { OrderShipping } from './OrderShipping'
import { ShopLink } from '../link/ShopLink'

// Hooks
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useOrders } from '@obeta/data/lib/hooks/useOrders'
import { useOrderSearch } from '@obeta/data/lib/hooks/useOrderSearch'
import { OrderForListPage, OrderItemForListPage } from '@obeta/models/lib/schema-models/order-list'
import styles from './OrderListItemV2.module.scss'
import { InputEditField } from '../input-edit-field/InputEditField'
import { TertiaryButton, TertiaryIconButton } from '../custom-button/CustomButton'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'
import useResizeObserver from '@react-hook/resize-observer'
import { modifyOrderIdForDisplay } from '@obeta/utils/lib/orders-helpers'
import { VirtualizedListAnimationContext } from '@obeta/utils/lib/virtualized-list-animation'

type Props = {
  order: OrderForListPage
  onEditName: (id: string, name: string) => void
  onToggleShowShipping: (index: number) => void
  tallestItemHeight?: number
  variant?: string
  index: number
}

function OrderListItemV2(props: Props, ref: React.ForwardedRef<HTMLDivElement>) {
  const { order, onToggleShowShipping, index, onEditName, tallestItemHeight, variant = '' } = props

  const { mobile, tabletAll } = useBreakpoints()
  const { t } = useTranslation()
  const history = useHistory()
  const { updateMetaData } = useOrders()
  const { getShippingAddress } = useOrderSearch()
  const useImgProxy = useFeatureToggle('UseImgProxy')

  const isRenderedOnStartPage = variant === 'startPage'
  const isArchivedOrder = order.itemStates.some((itemState) => itemState.type === 'Archived')

  // Set image grid size by breakpoint
  let imageGridSize
  if (mobile || isRenderedOnStartPage) {
    imageGridSize = 7
  } else if (tabletAll) {
    imageGridSize = 12
  } else {
    imageGridSize = 15
  }
  const IMAGE_GRID_SIZE = imageGridSize

  const [isRenaming, setIsRenaming] = useState<boolean>(false)
  const [showShipping, setShowShipping] = useState<boolean>(false)

  const onToggleShowShippingCmp = () => {
    setShowShipping((prevShowShipping) => !prevShowShipping)
  }

  /**
   * Handler to set order name by input field.
   * @param orderName Order name
   */
  const onEditNameCmp = useCallback(
    (orderName: string) => {
      const editName = async (orderName: string) => {
        const data = await updateMetaData({
          orderId: order.id,
          orderName,
        })
        if (data?.success) {
          onEditName(order.id, orderName)
        }
      }
      editName(orderName)
      setIsRenaming(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order]
  )

  const getOrderItemImageForDisplay = (item: OrderItemForListPage | undefined): JSX.Element => {
    if (!item) {
      return <div />
    }

    const isCustomArticle = item.product?.type === 'custom'

    // Show supplier image for custom item
    if (isCustomArticle && item.supplierImageData?.large) {
      return (
        <img alt={item.id} className={styles.imageGridItem} src={item.supplierImageData.large} />
      )
    }
    // Show product image for regular item
    const validImgProxyUrl = ensureValidImgProxyUrl(item.product?.images?.[0]?.url)
    if (!isCustomArticle && item.product?.oxomiId && item.product?.supplierId) {
      if (item.product?.images && validImgProxyUrl && useImgProxy) {
        return (
          <ImgProxyImage
            className={styles.imageGridItem}
            url={validImgProxyUrl}
            width={item.product.images[0].width ?? undefined}
            title={item.product.title}
            mobileWidthRem={1}
            tabletWidthRem={1}
            tabletWideWidthRem={1}
            desktopWidthRem={1}
          />
        )
      } else if (item.product?.imageData?.images?.[0]?.large) {
        return (
          <ProductOxomiImage
            alt={item.id}
            src={item.product?.imageData.images[0].large}
            oxomiId={item.product?.oxomiId}
            supplierId={item.product?.supplierId}
            supplierImage={item.supplierImageData.large}
          />
        )
      }
    }

    // Show supplier image for regular item
    else if (!isCustomArticle && item.supplierImageData?.large) {
      return (
        <img alt={item.id} className={styles.imageGridItem} src={item.supplierImageData?.large} />
      )
    }

    // Show fallback image for either regular or custom items
    return <img alt={item.id} className={styles.imageGridItem} src={brokenimage} />
  }

  // Reuseable components
  const headerActions = (
    <div className={styles.headerActions}>
      <TertiaryIconButton
        icon={<EditIcon />}
        size={mobile ? 'large' : 'small'}
        onClick={() => setIsRenaming(true)}
      />
    </div>
  )

  const headerLabels = (
    <div className={styles.headerLabels}>
      {order.itemStates.map((itemState) => (
        <OrderStateItem key={itemState.type} itemState={itemState} />
      ))}
    </div>
  )

  const modifiedOrderIdForDisplay = modifyOrderIdForDisplay(order.id)

  const headerName = !isRenaming ? (
    <div className={clsx(styles.headerName, !isRenderedOnStartPage && styles.noWhiteSpaceWrap)}>
      {order.name !== '' && (
        <Typography variant="boldText">{`${order.name} - ${modifiedOrderIdForDisplay} (${order.itemCount})`}</Typography>
      )}
      {order.name === '' && (
        <Typography variant="boldText">{`${modifiedOrderIdForDisplay} (${order.itemCount})`}</Typography>
      )}
    </div>
  ) : (
    <InputEditField
      placeholder={t('ORDERS.RENAME.PLACEHOLDER')}
      preventDefault
      value={order.name}
      onClose={() => setIsRenaming(false)}
      onSubmit={onEditNameCmp}
    />
  )

  const headerNameAndLabels = (
    <div className={styles.headerLabels}>
      {headerName}
      {order.itemStates.map((itemState) => (
        <OrderStateItem key={itemState.type} itemState={itemState} />
      ))}
    </div>
  )

  const button = mobile ? (
    <TertiaryIconButton
      size="large"
      icon={<ChevronRightIcon />}
      onClick={() => {
        history.push(`/order-details/${order.id}`)
      }}
    />
  ) : (
    <TertiaryButton
      rightIcon={<ChevronRightIcon />}
      size={tabletAll ? 'large' : 'small'}
      onClick={() => {
        history.push(`/order-details/${order.id}`)
      }}
    >
      {t('ORDERS.DETAILS.SHOW_DETAILS')}
    </TertiaryButton>
  )

  const [isResizing, setIsResizing] = useState(false)
  useEffect(() => {
    let timeout
    const updateOnResize = () => {
      setIsResizing(true)
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setIsResizing(false)
      }, 400)
    }
    window.addEventListener('resize', updateOnResize)
    return () => {
      window.removeEventListener('resize', updateOnResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const animationContext = useContext(VirtualizedListAnimationContext)

  const collapseElementRef = useRef<HTMLElement>(null)

  const [animationState, setAnimationState] = useState<{
    isAnimating: boolean
    element: HTMLElement | null
    initialHeight: number
  }>({ isAnimating: false, element: null, initialHeight: 0 })

  function handleAnimationStart(node: HTMLElement) {
    if (animationState.isAnimating) {
      return
    }

    const rect = node.getBoundingClientRect()

    setAnimationState({
      isAnimating: true,
      element: node,
      initialHeight: rect.height,
    })

    animationContext?.startAnimating(index)
  }

  function handleAnimationEnd() {
    setAnimationState({
      isAnimating: false,
      element: null,
      initialHeight: 0,
    })

    animationContext?.stopAnimating()

    onToggleShowShipping(index)
  }

  useResizeObserver(animationState.element, (entry) => {
    // The observer callback sometimes fires before handleAnimationStart. In
    // this case the initial height will be wrong and we can't calculate the
    // real offset. We should ignore this call and update the UI on the next.
    if (!animationState.isAnimating) return

    const box = entry.borderBoxSize[0]
    const height = box.blockSize

    const diff = height - animationState.initialHeight

    animationContext?.onHeightChange(diff)
  })

  return (
    <ShopLink href={`/order-details/${order.id}`} underline="none" className={styles.link}>
      <Card
        className={clsx(isRenderedOnStartPage ? styles.startPageCard : styles.card)}
        elevation={1}
      >
        <div className={styles.cardHead}>
          <div className={styles.header}>
            {mobile && (
              <>
                <div className={styles.headerInfo}>
                  {headerName} {!isRenderedOnStartPage && !isArchivedOrder && headerActions}
                </div>
                {headerLabels}
              </>
            )}
            {!mobile && (
              <>
                {headerNameAndLabels}
                {!isRenderedOnStartPage && !isArchivedOrder && headerActions}
              </>
            )}
          </div>
          <OrderInfo order={order} isMinimisedStartPageVersion={isRenderedOnStartPage} />
        </div>
        <div
          ref={ref}
          style={
            !isResizing && tallestItemHeight !== 0
              ? {
                  height: `${tallestItemHeight}px`,
                }
              : {}
          }
          className={clsx(
            isRenderedOnStartPage ? styles.shippingInfoAndPreviewImageContainer : null
          )}
        >
          <div className={styles.shipping}>
            <div className={styles.shippingInfo}>
              <div className={styles.shippingInfoHeader}>
                {isRenderedOnStartPage ? (
                  <Typography variant={'bodyBold'}>{t('ORDERS.SHIPPING.TITLE')}</Typography>
                ) : (
                  <TertiaryButton
                    size={mobile || tabletAll ? 'large' : 'small'}
                    rightIcon={showShipping ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    onClick={onToggleShowShippingCmp}
                  >
                    {t('ORDERS.SHIPPING.TITLE')}
                  </TertiaryButton>
                )}
              </div>

              <Collapse
                className={styles.shippingInfoContent}
                in={showShipping || isRenderedOnStartPage}
                timeout={'auto'}
                mountOnEnter
                unmountOnExit
                ref={collapseElementRef}
                onEntering={handleAnimationStart}
                onEntered={handleAnimationEnd}
                onExiting={handleAnimationStart}
                onExited={handleAnimationEnd}
              >
                <OrderShipping
                  address={getShippingAddress(
                    order.shippingAddress,
                    order.shippingType,
                    order.storeId
                  )}
                  date={order.shippingDate}
                  type={order.shippingType}
                />
              </Collapse>
            </div>
          </div>
          <div
            className={clsx(styles.details, isRenderedOnStartPage && styles.detailsExtraTopSpacing)}
          >
            <div className={styles.detailsImages}>
              {order?.items &&
                order.items
                  .slice(
                    0,
                    order.itemCount > IMAGE_GRID_SIZE ? IMAGE_GRID_SIZE - 1 : IMAGE_GRID_SIZE
                  )
                  .map((item) => (
                    <OrderItemImage
                      key={item.id}
                      imageForDisplay={getOrderItemImageForDisplay(item)}
                    />
                  ))}
              {/* Render, if less preview images shown than total items in order */}
              {order.items && order.itemCount > IMAGE_GRID_SIZE && (
                <div className={styles.detailsImagesMore}>
                  <MoreHorizIcon />
                </div>
              )}
            </div>
            {!isRenderedOnStartPage && button}
          </div>
        </div>
      </Card>
    </ShopLink>
  )
}

const OrderListItemV2Export = memo(forwardRef(OrderListItemV2))

export { OrderListItemV2Export as OrderListItemV2 }
